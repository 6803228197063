<template>
	<div>
		<el-result style="margin-top: 60px;" icon="success" title="报名成功" subTitle="我们会尽快联系您完成线下报名">
		    <template slot="extra">
				<el-button type="primary" size="medium" @click="handledClick()">返回</el-button>
		    </template>
		</el-result>
	</div>
</template>

<script>
	export default {
		name: "EnrollResult",
		methods:{
			handledClick(){
				const url = this.$router.push({ name: 'TalentProgress' });
			}
		}
	}
</script>

<style scoped>
</style>